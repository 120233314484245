<template src="@/../../../templates/brand/components/templates/shop/product/ProductCustomize.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
import countDown from '@/components/Countdown.vue'
import Lightbox from '@/components/Lightbox.vue'
import imageFile from '@/models/FileModel'
import floatPanel from '@/components/FloatPanel.vue'
import mime from 'mime-types'
// import kramed from 'kramed'

const allowed = ['jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'ppt', 'pptx']
const sizeOrder = [ 'jm', 'jl', 'jx', 'p', 'xs', 's', 'm', 'sm', 'l', 'x', 'xl', 'lxl', 'xxl', '2', '2x', '2xl', 'xxxl', '3', '3x', '3xl', 'xxxxl', '4', '4x', '4xl', 'xxxxxl', '5', '5x', '5xl' ]

export default {
  created () {
    this.$http.get('/files?type=logo')
    .then(res => {
      if (typeof res.data !== 'string') {
        this.logos = res.data
        if (
          this.product.catBindings &&
          this.product.catBindings.logos &&
          this.product.catBindings.logos.length
        ) {
          this.filteredLogos = res.data.filter(l =>
            _.intersection(l.category, this.product.catBindings.logos).length
          )
        } else {
          this.filteredLogos = res.data
        }
        // if default location has a logo preset, add it to customizations
        if (_.isEmpty(this.customizations) && this.currentView.loc.preset) {
          this.addLogo(this.filteredLogos.find(l => l._id === this.currentView.loc.preset))
        }
      }
      this.loaded = true
    })
    this.$http.get('/files?type=user&subType=logo')
    .then(res => {
      if (typeof res.data !== 'string') this.userLogos = res.data
    })
    this.setup()

    const _profile = _.assign({}, this.user.profile)
    if (!this.user.profile.shoppingcarts) _profile.shoppingcarts = {}
    if (this.settings.cart !== 'default' && !_profile.shoppingcarts[this.settings.cart]) {
      _profile.shoppingcarts[this.settings.cart] = {
        items: {},
        details: {},
        label: this.settings.cartLabel
      }
      this.profileUpdate({ user: this.user, profile: _profile })
      .then(() => {
        this.loaded = true
      })
    }
  },
  components: {
    // productPricing,
    // pricingTiers,
    floatPanel,
    Lightbox,
    countDown
  },
  props: {
    isProgram: {
      type: Boolean,
      default: false
    },
    isCollection: {
      type: Boolean,
      default: false
    },
    section: {
      type: String,
      default: ''
    },
    update: String
  },
  // component data members
  data () {
    return {
      loaded: false,
      title: '',
      allowUpload: true, // NB: this should be configurable value
      selectedQtys: {},
      cartid: '',
      // relatedItems: [],
      logos: [],
      filteredLogos: [],
      userLogos: [],
      logoMenuDefaultTop: 276,
      logoMenuDefaultLeft: 40,
      logoMenuPosition: {
        top: this.logoMenuDefaultTop,
        left: this.logoMenuDefaultLeft
      },
      customizations: {},
      specialInstructions: '',
      customRects: {},
      currentView: false,
      showActiveLoc: false,
      showLogoSelector: false, // controls visibility of logo palette
      relatedLocations: [],
      addDealerLogo: false,
      upload: null,
      newPreview: null,
      flash: null,
      attachNew: false,
      selectedVar: null,
      // showAddToCart: false,
      showImages: {
        hero: false,
        variations: false
      },
      lightboxVisible: false,
      modalimg: {
        url: null,
        name: null
      }
    }
  },
  computed: {
    ...mapState({
      collectionProducts: ({ Product }) => Product.products,
      collection: ({ Collection }) => Collection.collection,
      user: ({ App }) => App.user,
      product: ({ Product }) => Product.atomicProduct, // || {content: {en: {}, fr: {} }}, //product,
      customImages: ({ Product }) => Product.customimages,
      // returnURL: ({ navigationContext }) => navigationContext ||
      //   this.$route.path.slice(0, this.$route.path.indexOf('/product')),
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    returnURL () {
      return this.$route.path.slice(0, this.$route.path.indexOf('/product'))
    },
    hero () {
      if (_.isEmpty(this.product)) return false
      if (this.product.images.length === 1) return this.product.images[0]
      return (
        _.find(i => i.default, this.product.images) ||
        _.sortBy(i => i.order, this.product.images)[0]
      )
    },

    secondaryImages () {
      if (_.isEmpty(this.product)) return false
      if (this.product.images.length < 2) return false
      return _.map(
        i => {
          if (i._id !== this.hero._id) return i
        },
        _.sortBy(i => i.order, this.product.images)
      )
    },
    relatedItems () {
      return _.filter(
        i =>
          ~this.product.relatedItems.indexOf(
            i.familyKey + i.variationField.key
          ),
        this.collectionProducts
      )
    },
    allowCustomOption () {
      if (!this.product.customizable || !_.get(this.product.SKU, this.selectedQtys) || !this.product.customMinQty) {
        return false
      }
      return (
        this.product.customizable &&
        this.selectedQtys[this.product.SKU].qty >= this.product.customMinQty
      )
    },
    settings () {
      return this.isProgram ? this.program.settings : this.collection.settings
    },
    cart () {
      if (!this.loaded) return{items: {}, details: {}, label: ''}
      let _cartid
      const _ent = this.isProgram ? this.program : this.collection
      if (this.settings.cart !== 'default') {
        this.setCartid(this.settings.cart)
        return this.user.profile.shoppingcarts[this.settings.cart]
      } else {
        let _cart
        if (this.settings.checkout && this.settings.communityVolume) {
          _cartid = 'preorders'
          _cart = this.user.profile.preorders
        } else if (_ent.allowCustom) {
          _cartid = 'customorders'
          _cart = this.user.profile.customorders
        } else {
          _cartid = 'cart'
          _cart = this.user.profile.cart
        }
        this.setCartid(_cartid)
        return _cart
      }
    },
    sortedProduct () {
      const mbrs = {}
      let _out = []
      if (!this.product.units || !this.product.units.length)
        return [this.product]
      if (
        (this.product.relatedBy.toLowerCase() === 'size' ||
        this.product.units.some(u => u.unitField.name.toLowerCase() === 'size')) &&
        this.product.units.some(u => !u.unitField.order)
      ) {
        this.product.units.forEach(
          item =>
            (mbrs[
              (item.unitField && item.unitField.key.toLowerCase()) ||
                item.size.toLowerCase()
            ] = item._id)
        )
        const _k = _.keys(mbrs)
        const _inter = _.intersection(_k, sizeOrder)
        if (_inter.length === _k.length) {
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.units.find(itm => itm._id === mbrs[item])
            )
          )
        } else if (_inter.length < _k.length) {
          const _diff = _.difference(_k, _inter)
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.units.find(itm => itm._id === mbrs[item])
            )
          )
          _diff.forEach(i => {
            _out.push(this.product.units.find(itm => itm._id === mbrs[i]))
          })
        } else {
          _out = this.product.units
        }
      } else {
        _out = this.product.units
      }
      return _out
    },
    prodTiers () {
      if (!this.product) return []
      const _sorted = _.sortBy(a => a.volume, this.product.adjustments)
      let _tiers = []
      if (this.product.minQty < _sorted[0].volume) {
        _tiers.push({
          label: [this.product.minQty, _sorted[0].volume - 1],
          value: this.product.price
        })
      }
      for (let i = 0, c = _sorted.length; i < c; i++) {
        if (!_sorted[i].value) {
          _tiers.push({
            label: [_sorted[i].volume],
            value: _sorted[i].value
          })
        } else if (i < _sorted.length - 1) {
          _tiers.push({
            label: [_sorted[i].volume, _sorted[i + 1].volume - 1],
            value: _sorted[i].value
          })
        } else {
          _tiers.push({
            label: [_sorted[i].volume, '+'],
            value: _sorted[i].value
          })
        }
      }
      return _tiers
    },
    locTiers () {
      if (!this.product) return []
      const _sorted = _.sortBy(a => a.volume, this.product.custom.adjustments)
      let _tiers = []
      if (this.product.minQty < _sorted[0].volume) {
        _tiers.push({
          label: [this.product.minQty, _sorted[0].volume - 1],
          value: this.product.custom.price
        })
      }
      for (let i = 0, c = _sorted.length; i < c; i++) {
        if (!_sorted[i].value) {
          _tiers.push({
            label: [_sorted[i].volume],
            value: _sorted[i].value
          })
        } else if (i < _sorted.length - 1) {
          _tiers.push({
            label: [_sorted[i].volume, _sorted[i + 1].volume - 1],
            value: _sorted[i].value
          })
        } else {
          _tiers.push({
            label: [_sorted[i].volume, '+'],
            value: _sorted[i].value
          })
        }
      }
      return _tiers
    },
    minCustomPrice () {
      return _.min(_.filter(t => t > 0, _.map(i => i.value, this.locTiers)))
    },
    orderTiers () {
      if (!this.product) return []
      let _calc = []
      let _locs = _.keys(this.customizations)
      for (let i = 0, c = this.prodTiers.length; i < c; i++) {
        _calc.push(
          this.prodTiers[i].value +
            (_locs.length > 1 ? this.locTiers[i].value * (_locs.length - 1) : 0)
        )
      }
      return _calc
    },
    totalQty () {
      return _.sum(_.map(i => i.qty, _.values(this.selectedQtys)))
    },
    showAddToCart () {
      let _qty = _.sum(_.map(i => i.qty, this.selectedQtys))
      return !(this.isCollection &&
        _qty < this.product.minQty &&
        !(_qty === 0 &&
          _qty !== _.sum(_.map(i => this.cart.items[i], _.intersection(_.keys(this.selectedQtys), _.keys(this.cart.items))))))
    },
    clearItems () {
      let _qty = _.sum(_.map(i => i.qty, this.selectedQtys))
      return (_qty === 0 &&
        _qty !== _.sum(_.map(i => this.cart.items[i], _.intersection(_.keys(this.selectedQtys), _.keys(this.cart.items))))
      )
    },
    customorderIsEmpty () {
      if (!this.user) return true
      return !_.keys(this.cart.items).length
    },
    getQuoteMessage () {
      let _qty = _.sum(_.map(i => i.qty, this.selectedQtys))
      let _max = _.find(
        p => p.value === _.last(this.prodTiers).value,
        this.product.adjustments
      )
      return !_max.value && _qty > _max.volume ? 'Email for a quote.' : ''
    },
    unitMinMax () {
      return _.reduce((acc, i) => {
          acc[i] = { min: 0, max: 0 }
          let _inv = !this.settings.ignoreInventory
            ? this.product.units.find(u => u.SKU === i).inventory
            : 0
          _inv = this.product.maxQty && this.product.maxQty > _inv
            ? _inv
            : this.product.maxQty
          acc[i].max = _inv
          acc[i].min = this.selectedQtys[i] && this.product.minQty
            ? this.product.minQty
            : 0
          return acc
        }, {}, _.keys(this.selectedQtys)
      )
    }
  },
  methods: {
    ...mapActions([
      'getCollection',
      'profileUpdate',
      'getProductFamily',
      'getCustomorderProducts'
    ]),
    setCartid (val) {
      this.cartid = val
    },
    productView: function (_loc, checkPalette) {
      if (checkPalette && this.showActiveLoc) return
      let loc = _loc || this.product.custom.locations.find(l => l.default) || this.product.custom.locations[0]

      this.currentView = {
        loc: loc,
        id: loc.id,
        image: this.customImages.find(i => i._id === loc.image)
      };
      this.logoMenuPosition.top = this.logoMenuDefaultTop + document.documentElement.scrollTop;

      if (~_.keys(this.customRects).indexOf((String)(loc.id)) || _loc) {
        this.positionRects();
      }
      this.showLogoSelector = true
      this.showActiveLoc = true;

    },
    positionRects () {
      let _r = {}
      _r[this.currentView.loc.id] = this.findLocFrame(this.currentView.loc)
      this.relatedLocations = this.commonProductView()
      _.forEach(l => {
        _r[l.loc.id] = this.findLocFrame(l.loc)
      }, this.relatedLocations)
      this.customRects = _r
    },
    commonProductView () {
      if (this.currentView.loc.common) {
        if (typeof this.currentView.loc.common === 'string') {
          return this.currentView.loc.common.split(',').map(r => {
            return {
              loc: this.product.custom.locations.find(l => Number(l.id) === Number(r.trim()))
            }
          })
        } else {
          return [{
              loc: this.product.custom.locations.find(l => l.id === this.currentView.loc.common)
            }]
        }
      } else {
        return []
      }
    },
    findLocFrame (loc) {
      const _pos =
        typeof loc.position === 'string'
          ? loc.position.split(' ')
          : loc.position[0].split(' ')
      let _base = document.getElementById('custom-view-base')
      const _coords = {
        top: parseInt(_pos[0], 10),
        right: parseInt(_pos[1], 10),
        bottom: parseInt(_pos[2], 10),
        left: parseInt(_pos[3], 10)
      }
      let _scale = {}
      let _baseRect, _parentRect, _top, _left

      _baseRect = _base.getBoundingClientRect()
      _parentRect = _base.parentElement.getBoundingClientRect()
      _scale = {
        x: _base.width / _base.naturalWidth,
        y: _base.height / _base.naturalHeight
      }
      _top = _coords.top * _scale.y
      _left = _coords.left * _scale.x + (_baseRect.left - _parentRect.left)

      return {
        position: 'absolute',
        border: 'transparent solid 1px',
        left: _left + 'px',
        top: _top + 'px',
        width: (_coords.right - _coords.left) * _scale.x + 'px',
        height: (_coords.bottom - _coords.top) * _scale.y + 'px'
      }
    },
    showLightbox (img) {
      this.modalimg = img
      this.lightboxVisible = true
      this.$emit('lightbox.show')
    },
    closeFloatPanel () {
      // this.$emit('close.floatPanel')
      this.showLogoSelector = false
      this.showActiveLoc = false
    },
    selectVar (v) {
      this.selectedVar = v
    },
    getItemLink (item) {
      if (item.variationKey) {
        return `/shop/${this.$route.params.collection}/product/${item.familyKey}/${item.variationKey}`
      } else {
        return `/shop/${this.$route.params.collection}/product/${item.familyKey}`
      }
    },
    addLogo (logo) {
      let _cust = _.assign({}, this.customizations)
      _cust[this.currentView.loc.content.en.name] = {
        loc: this.currentView.loc,
        logo: logo
      }
      this.customizations = _cust
      if (_.intersection(_.keys(this.cart.items), _.keys(this.selectedQtys)).length) {
        // stuff is already in cart, remove from cart and update
        this.updateCart()
      }
    },
    clearLoc () {
      let _cust = _.assign({}, this.customizations)
      delete _cust[this.currentView.loc.content.en.name]
      this.customizations = _cust
      if (_.intersection(_.keys(this.cart.items), _.keys(this.selectedQtys)).length) {
        // stuff is already in cart, remove from cart and update
        this.updateCart()
      }
    },
    updateCart () {
      const _profile = _.assign({}, this.user.profile)
      const _cart = _profile[this.cartid] || _profile.shoppingcarts[this.cartid]
      const skus = _.keys(this.selectedQtys)
      for (const sku of skus) {
        if (_cart.details[sku]) {
          _cart.details[sku].customizations = this.customizations
        }
      }

      this.profileUpdate({ user: this.user, profile: _profile })
        .then(() => {
          this.$emit('saved.cart')
          return this.getCustomorderProducts()
        })
        .then(() => {
          this.selectedQtys = this.setInitialQtys()
        })
        .catch(err => console.log('profileUpdate threw an error', err))
    },
    addToCart () {
      const _profile = _.assign({}, this.user.profile)
      const _cart = _.get(this.cartid, _profile) || _.get(this.cartid, _profile.shoppingcarts)
      let _updateRequired = false
      let _qty = _.sum(_.map(i => i.qty, this.selectedQtys))
      
      if (this.isCollection &&
        _qty < this.product.minQty &&
        !(_qty === 0 && 
        _qty !== _.sum(_.map(i => this.cart.items[i], _.intersection(_.keys(this.selectedQtys), _.keys(this.cart.items)))))
      ) return

      for (const k in this.selectedQtys) {
        if (!this.selectedQtys[k].qty && _.has(k, _cart.items)) {
          delete _cart.items[k]
          delete _cart.details[k]
          _updateRequired = true
        } else if (
          (!_cart.items[k] && this.selectedQtys[k].qty) ||
          (this.selectedQtys[k].qty &&
            this.selectedQtys[k].qty !== _cart.items[k]) ||
          (_.get('customorders.details.' + k, _profile) &&
            !_.isEqual(this.customizations, _cart.details[k].customizations))
        ) {
          if (this.selectedQtys[k].qty) {
            _cart.items[k] = this.selectedQtys[k].qty
            _cart.details[k] = {}
            _cart.details[k].instructions = this.specialInstructions
            _cart.details[k].added = Date.now()
          }
          if (this.isCollection) {
            _.has(k, _cart.details)
              ? (_cart.details[k].type = 'collection')
              : (_cart.details[k] = { type: 'collection' })
            _cart.details[k].url = this.section
            if (this.product.units) {
              _.each(i => {
                if (_cart.details[i.SKU]) {
                  _cart.details[k].customizations = this.customizations
                  _cart.details[k].instructions = this.specialInstructions
                }
              }, this.product.units)
            } else {
              _cart.details[k].customizations = this.customizations
              _cart.details[k].instructions = this.specialInstructions
            }
          }
          if (this.isProgram) {
            _cart.details[k].type = 'program'
            _cart.details[k].url = this.section
            _cart.details[k].customizations = this.customizations
            _cart.details[k].instructions = this.specialInstructions
          }
          _updateRequired = true
        }
      }

      if (_updateRequired) {
        this.profileUpdate({ user: this.user, profile: _profile })
          .then(() => {
            this.$emit('saved.cart')
            return this.getCustomorderProducts()
          })
          .then(() => {
            this.selectedQtys = this.setInitialQtys()
          })
          .catch(err => console.log('profileUpdate threw an error', err))
      }
      return true
    },
    setInitialQtys () {
      let _initialvals = {}
      if (!this.product.units || !this.product.units.length) {
        _initialvals[this.product.SKU] = {
          qty: 0
        }
        if (this.cart.items[this.product.SKU])
          _initialvals[this.product.SKU].qty = this.cart.items[this.product.SKU]
      } else {
        _.each(i => {
          _initialvals[i.SKU] = { qty: 0 }
          if (this.cart.items[i.SKU]) {
            _initialvals[i.SKU].qty = this.cart.items[i.SKU]
          }
        }, this.product.units)
      }
      let _cust = {}
      if (this.product.units) {
        _.each(i => {
          if (this.cart.details[i.SKU]) {
            _cust = this.cart.details[i.SKU].customizations
            this.specialInstructions = this.cart.details[i.SKU].instructions
            return false
          }
        }, this.product.units)
        this.customizations = _cust
      } else {
        this.customizations = _.has(this.product.SKU, this.cart.details)
          ? this.cart.details[this.product.SKU].customizations
          : {}
      }
      return _.assign({}, _initialvals)
    },
    updateItemQtys (evt) {
      let _p
      let _val = parseInt(evt.target.value, 10)
      if (!this.product.units || !this.product.units.length) _p = this.product
      else
        _p = _.find(i => i.SKU === evt.target.dataset.sku, this.product.units)
      let _minQty = _p.minQty
        ? _p.minQty
        : this.product.minQty
        ? this.product.minQty
        : 1
      if (_val === 0) {
        this.$emit('qtychange', {
          _id: evt.target.dataset.id,
          sku: evt.target.dataset.sku,
          qty: _val
        })
      } else if (_minQty <= _val) {
        if (!this.settings.ignoreInventory && _val > _p.inventory) {
          _val = _p.inventory
          evt.target.value = _p.inventory
        }
        this.$emit('qtychange', {
          _id: evt.target.dataset.id,
          sku: evt.target.dataset.sku,
          qty: _val
        })
      }
    },
    uploadFile (evt) {
      console.info('now create the file')
      if (!evt.target.files.length) return
      const file = evt.target.files[0]

      if (this.isAllowedType(file)) {
        const fd = new FormData()
        fd.append('user', this.user._id)
        fd.append('recordType', 'user')
        fd.append('file', file)
        this.$http
          .post('/files/upload', fd, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(res => {
            const _res = res.data
            const _f = new imageFile()
            if (!_res.err) {
              _f.set('type', 'user')
              _f.set('subType', 'logo')
              _f.set('user', this.user._id)
              _f.set('filename', _res.filename)
              _f.set('path', _res.path)
              _f.set('url', _res.url)
              _f.set('mimeType', _res.mimeType)
              this.$http.post('/files', _f.getData())
                .then(() => {
                  return this.$http.get('/files?type=user&subType=logo').then(res => {
                    if (typeof res.data !== 'string') {
                      this.userLogos = res.data
                    }
                  })
                })
                .catch(err => console.error('Error adding file. ', err))
            } else {
              throw new Error(res.err)
            }
          })
          .catch(err => console.error('Error uploading file.', err))
      }
    },
    isAllowedType (file) {
      return allowed.some(type => mime.lookup(type) === file.type)
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    setup () {
      window.addEventListener('resize', this.positionRects)
      this.selectedQtys = this.setInitialQtys()
      if (this.totalQty || this.product.custom.locations.find(p => p.default && p.preset)) {
        this.productView()
        this.showLogoSelector = false
        this.showActiveLoc = false
      }

      this.$watch('product', () => {
        this.$broadcast('close.floatPanel')
        this.currentView = false
        this.customRects = {}
        this.countSelected()
        this.selectedQtys = this.setInitialQtys()
        if (this.totalQty) {
          console.info(' setup, set prod view to cart items for ', this.product.SKU)
          this.productView()
        }
      })
      this.$watch('update', () => {
        this.$broadcast('close.floatPanel')
        this.currentView = false
        this.customRects = {}
        this.selectedVar = null
        this.countSelected()
        this.selectedQtys = this.setInitialQtys()
      });
    }
  },
  beforeDestroy () {
    this.currentView = false
    this.customRects = {}
    // this.$emit('close.floatPanel')
    this.showLogoSelector = false
    window.removeEventListener('resize', this.positionRects)
  },
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/product/ProductCustomize.css"></style>
