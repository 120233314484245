<template>
  <div>
    <h3>Per Location Ordering:</h3>
    <p
      v-if="
        isCollection
          ? collection.settings.ignoreInventory
          : program.settings.ignoreInventory
      "
    >
      Enter # of CASES for each location below.
    </p>
    <p v-else>
      Enter # of CASES for each location below.
    </p>
    <table v-if="loaded" class="table single">
      <thead>
        <tr>
          <!-- <th width="25%" class="ml-size-title">{{ sortedProduct[0].unitField.name }}</th> -->
          <th class="ml-title">Locations<br /></th>
          <th v-for="unit in sortedProduct" class="ml-size" :key="unit.SKU">
            Cases:<br />{{ unit.unitField.value }}
            <span
              v-if="
                isCollection
                  ? !collection.settings.ignoreInventory
                  : !program.settings.ignoreInventory
              "
              >{{ unit.inventory }}</span
            >
          </th>
          <!-- <th v-for="unit in sortedProduct" class="ml-size">In stock:<br /><span v-if="isCollection ? !collection.settings.ignoreInventory : !program.settings.ignoreInventory">{{ unit.inventory }}</span></th> -->
          <th class="ml-fill"></th>
        </tr>
        <!-- <tr v-if="!collection.settings.ignoreInventory">
        <th width="25%" class="ml-inv-count-title">Inventory</th>
        <th v-for="unit in sortedProduct" width="10%" class="ml-inv-count">{{ unit.inventory }}</th>
    </tr> -->
        <!-- <tr>
        <th width="25%">Location(s)</th>
        <th width="100%">&nbsp;</th>
    </tr> -->
      </thead>
      <tbody>
        <tr v-for="loc in shippingAddresses" :key="loc._id">
          <td class="ml-loc">{{ loc.content[$i18n.locale].name }}</td>
          <td
            v-for="item in sortedProduct"
            :key="item._id"
            class="ml-input"
            :class="{
              cartvalue:
                cart.details[item.SKU] &&
                cart.details[item.SKU].locations[loc._id] &&
                selectedQtys[item.SKU]
            }"
          >
            <input
              v-if="selectedQtys[item.SKU]"
              v-model="selectedQtys[item.SKU].locations[loc._id]"
              class="purchasable"
              :data-id="item._id"
              :data-sku="item.SKU"
              :data-location="loc._id"
              type="number"
              placeholder="0"
              :min="unitMinMax[item.SKU] ? unitMinMax[item.SKU].min : 0"
              :max="unitMinMax[item.SKU] ? unitMinMax[item.SKU].max : 0"
              @change="updateItemQtys"
              :title="
                cart.items[item.SKU] &&
                selectedQtys[item.SKU] &&
                selectedQtys[item.SKU].qty &&
                cart.details[item.SKU].locations[loc._id] ===
                  selectedQtys[item.SKU].locations[loc._id]
                  ? 'items already in cart'
                  : ''
              "
              :disabled="respectInventory && item.inventory < 1"
              number
            />
          </td>
          <td class="ml-fill"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'

const sizeOrder = [ 'jm', 'jl', 'jx', 'p', 'xs', 's', 'm', 'sm', 'l', 'x', 'xl', 'lxl', 'xxl', '2', '2x', '2xl', 'xxxl', '3', '3x', '3xl', 'xxxxl', '4', '4x', '4xl', 'xxxxxl', '5', '5x', '5xl' ]


export default {
  name: 'MultilocUnits',
  // component lifecycle events
  created () {
    const _ent = this.isCollection ? 'collection' : 'program'
    if (this[_ent] && this[_ent].settings) {
      this.respectInventory = !this[_ent].settings.ignoreInventory
      if (!this.locations.length) {
        this.getLocations(this.user._id).then(() => {
          this.selectedQtys = this.setInitialQtys()
        })
      } else {
        this.selectedQtys = this.setInitialQtys()
      }
    } else {
      let unwatch = this.$watch(_ent, () => {
        this.respectInventory = !this[_ent].settings.ignoreInventory
        if (!this.locations.length) {
          this.getLocations(this.user._id).then(() => {
            this.selectedQtys = this.setInitialQtys()
          })
        } else {
          this.selectedQtys = this.setInitialQtys()
        }
        unwatch()
      })
    }
    this.$watch('product', () => {
      this.selectedQtys = this.setInitialQtys()
    })
    this.$watch('cart', () => {
      this.selectedQtys = this.setInitialQtys()
    })
  },
  props: ['product', 'isCollection', 'cart', 'collection', 'program'],
  data () {
    return {
      loaded: false,
      selectedQtys: {},
      respectInventory: true,
      showSkus: false // so we don't get ugly errors from template before props arrive...
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      locations: ({ Location }) => Location.locations,
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    sortedProduct () {
      const mbrs = {}
      let _out = []
      if (!this.product.units || !this.product.units.length)
        return [this.product]
      if (
        (this.product.relatedBy.toLowerCase() === 'size' ||
        this.product.units.some(
          u => String(u.unitField.name).toLowerCase() === 'size'
        )) &&
        this.product.units.some(u => !u.unitField.order)
      ) {
        this.product.units.forEach(
          item =>
            (mbrs[
              (item.unitField && String(item.unitField.key).toLowerCase()) ||
                String(item.size).toLowerCase()
            ] = item._id)
        )
        const _k = _.keys(mbrs)
        const _inter = _.intersection(_k, sizeOrder)
        if (_inter.length === _k.length) {
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.units.find(itm => itm._id === mbrs[item])
            )
          )
        } else if (_inter.length < _k.length) {
          const _diff = _.difference(_k, _inter)
          _out = _.compact(
            sizeOrder.map(item =>
              this.product.units.find(itm => itm._id === mbrs[item])
            )
          )
          _diff.forEach(i => {
            _out.push(this.product.units.find(itm => itm._id === mbrs[i]))
          })
        } else {
          _out = this.product.units
        }
      } else {
        _out = this.product.units
      }
      return _out
    },
    shippingAddresses () {
      return this.locations.filter(l => l.type !== 'registration')
    },
    settings () {
      return this.isCollection
        ? this.collection.settings
        : this.program.settings
    },
    unitMinMax () {
      if (!this.product || !this.product.units) return 0
      return _.reduce(
        (acc, i) => {
          acc[i] = { min: 0, max: 0 }
          let _inv = 'inf'
          if (!this.settings.ignoreInventory) {
            const _p = this.product.units.find(u => u.SKU === i)
            _inv = _p ? _p.inventory : 0
            if (this.product.maxQty)
              _inv = this.product.maxQty > _inv ? _inv : this.product.maxQty
          } else if (this.product.maxQty) _inv = this.product.maxQty
          acc[i].max = _inv
          acc[i].min =
            this.selectedQtys[i] &&
            this.product.minQty &&
            this.product.minQty > 1
              ? this.product.minQty
              : 0
          return acc
        },
        {},
        _.keys(this.selectedQtys)
      )
    }
  },
  methods: {
    ...mapActions(['getLocations']),
    setInitialQtys () {
      const _initialvals = {}
      const _locvals = this.locations.reduce((acc, i) => {
        acc[i._id] = 0
        return acc
      }, {})

      if (!this.product.units || !this.product.units.length) {
        _initialvals[this.product.SKU] = {
          qty: 0,
          locations: _.assign({}, _locvals)
        }
        if (this.cart.items[this.product.SKU]) {
          _initialvals[this.product.SKU].qty = this.cart.items[this.product.SKU]
          _initialvals[this.product.SKU].locations = this.cart.details[
            this.product.SKU
          ].locations
        }
      } else {
        _.each(i => {
          _initialvals[i.SKU] = { qty: 0, locations: _.assign({}, _locvals) }
          if (this.cart.items[i.SKU]) {
            _initialvals[i.SKU].qty = this.cart.items[i.SKU]
            _initialvals[i.SKU].locations = _.assign(
              {},
              this.cart.details[i.SKU].locations
            )
          }
        }, this.product.units)
      }

      this.showSkus = true
      this.loaded = true
      return _.assign({}, _initialvals)
    },
    updateItemQtys (evt) {
      let _p
      let _val = parseInt(evt.target.value, 10)
      const _loc = evt.target.dataset.location
      const _id = evt.target.dataset.id
      const _sku = evt.target.dataset.sku
      _p = _.find(i => i.SKU === evt.target.dataset.sku, this.sortedProduct)
      let _baseqty = _.reduce(
        (acc, i) => {
          if (i !== _loc) acc += this.selectedQtys[_sku].locations[i]
          return acc
        },
        0,
        _.keys(this.selectedQtys[_sku].locations)
      )
      if (_val === 0) {
        this.selectedQtys[_sku].qty = _.sum(
          _.values(this.selectedQtys[_sku].locations)
        )
        this.$emit('qtyChange', {
          location: _loc,
          _id: _id,
          sku: _sku,
          qty: _val
        })
        window.$(evt.target).removeClass('invalid')
        window.$(evt.target).attr('min', 0)
      } else {
        if (
          this.unitMinMax[_sku].max !== 'inf' &&
          this.unitMinMax[_sku].max < _val
        ) {
          _val = this.unitMinMax[_sku].max
        } else if (
          !this.settings.ignoreInventory &&
          _p.inventory < _baseqty + _val
        ) {
          _val = _p.inventory - _baseqty
        }
        this.selectedQtys[_sku].locations[_loc] = _val
        this.selectedQtys[_sku].qty = _.sum(
          _.values(this.selectedQtys[_sku].locations)
        )
        this.$emit('qtyChange', {
          location: _loc,
          _id: _id,
          sku: _sku,
          qty: _val
        })
        window.$(evt.target).addClass('invalid')
        window.$(evt.target).attr('min', _p)
      }
    },
    priceQty (item) {
      if (!item) return // why does this get called with no value?
      // return item price from adjustments for quantity range of order
      const tier = _.find(
        itm =>
          itm.volume > _.sum(_.map(i => i.qty, _.values(this.selectedQtys))),
        this.product.adjustments
      )
      if (tier) return tier.value
      else if (item.price) return item.price
      else return this.product.price
    },
    inventoryControl (inv) {
      if (inv > 0) {
        return inv
      } else {
        return '<i class="fa fa-exclamation-triangle" style="color:#f60;" title="Item is on backorder" aria-hidden="true"></i>'
      }
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    }
  },
  events: {
    'saved.cart' () {
      this.setInitialQtys()
    },
    'product.changed' () {
      this.showSkus = false
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/product/MultilocationUnitGrid.css"></style>
